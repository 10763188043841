/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type GenderType = 'Unknown' | 'Male' | 'Female' | 'Other';

export const GenderType = {
    Unknown: 'Unknown' as GenderType,
    Male: 'Male' as GenderType,
    Female: 'Female' as GenderType,
    Other: 'Other' as GenderType
};