/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type AnswerValueType = 'Unknown' | 'Percentage' | 'Integer' | 'Text' | 'SingleChoice' | 'MultipleChoice';

export const AnswerValueType = {
    Unknown: 'Unknown' as AnswerValueType,
    Percentage: 'Percentage' as AnswerValueType,
    Integer: 'Integer' as AnswerValueType,
    Text: 'Text' as AnswerValueType,
    SingleChoice: 'SingleChoice' as AnswerValueType,
    MultipleChoice: 'MultipleChoice' as AnswerValueType
};