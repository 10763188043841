/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type VideoFormatType = 'Unknown' | 'Horisontal_16x9' | 'Vertical_4x3';

export const VideoFormatType = {
    Unknown: 'Unknown' as VideoFormatType,
    Horisontal16x9: 'Horisontal_16x9' as VideoFormatType,
    Vertical4x3: 'Vertical_4x3' as VideoFormatType
};