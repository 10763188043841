/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type TranslationStatusType = 'Unknown' | 'NeedsTranslation' | 'Missing' | 'OutOfDate' | 'UpToDate' | 'AllStrings';

export const TranslationStatusType = {
    Unknown: 'Unknown' as TranslationStatusType,
    NeedsTranslation: 'NeedsTranslation' as TranslationStatusType,
    Missing: 'Missing' as TranslationStatusType,
    OutOfDate: 'OutOfDate' as TranslationStatusType,
    UpToDate: 'UpToDate' as TranslationStatusType,
    AllStrings: 'AllStrings' as TranslationStatusType
};