/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type EventStatusType = 'Unknown' | 'Draft' | 'Published' | 'Aborted' | 'Deleted';

export const EventStatusType = {
    Unknown: 'Unknown' as EventStatusType,
    Draft: 'Draft' as EventStatusType,
    Published: 'Published' as EventStatusType,
    Aborted: 'Aborted' as EventStatusType,
    Deleted: 'Deleted' as EventStatusType
};