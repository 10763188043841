/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type UserStatusType = 'Unknown' | 'Pending' | 'Active' | 'Inactive' | 'Canceled' | 'Expired';

export const UserStatusType = {
    Unknown: 'Unknown' as UserStatusType,
    Pending: 'Pending' as UserStatusType,
    Active: 'Active' as UserStatusType,
    Inactive: 'Inactive' as UserStatusType,
    Canceled: 'Canceled' as UserStatusType,
    Expired: 'Expired' as UserStatusType
};