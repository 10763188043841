/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type UnitOfMeasureType = 'Unknown' | 'Length' | 'Weight' | 'Time' | 'Volume';

export const UnitOfMeasureType = {
    Unknown: 'Unknown' as UnitOfMeasureType,
    Length: 'Length' as UnitOfMeasureType,
    Weight: 'Weight' as UnitOfMeasureType,
    Time: 'Time' as UnitOfMeasureType,
    Volume: 'Volume' as UnitOfMeasureType
};