/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type CoachType = 'Unknown' | 'Regular' | 'Head';

export const CoachType = {
    Unknown: 'Unknown' as CoachType,
    Regular: 'Regular' as CoachType,
    Head: 'Head' as CoachType
};