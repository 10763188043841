/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type MealPlansSortSpecification = 'NameASC' | 'NameDESC' | 'RuntimeASC' | 'RuntimeDESC' | 'CreatedByASC' | 'CreatedByDESC' | 'CreatedDateASC' | 'CreatedDateDESC' | 'UsageYearASC' | 'UsageYearDESC';

export const MealPlansSortSpecification = {
    NameASC: 'NameASC' as MealPlansSortSpecification,
    NameDESC: 'NameDESC' as MealPlansSortSpecification,
    RuntimeASC: 'RuntimeASC' as MealPlansSortSpecification,
    RuntimeDESC: 'RuntimeDESC' as MealPlansSortSpecification,
    CreatedByASC: 'CreatedByASC' as MealPlansSortSpecification,
    CreatedByDESC: 'CreatedByDESC' as MealPlansSortSpecification,
    CreatedDateASC: 'CreatedDateASC' as MealPlansSortSpecification,
    CreatedDateDESC: 'CreatedDateDESC' as MealPlansSortSpecification,
    UsageYearASC: 'UsageYearASC' as MealPlansSortSpecification,
    UsageYearDESC: 'UsageYearDESC' as MealPlansSortSpecification
};