/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type QuizQuestionType = 'Unknown' | 'SingleCorrect' | 'MultipleCorrect' | 'Any';

export const QuizQuestionType = {
    Unknown: 'Unknown' as QuizQuestionType,
    SingleCorrect: 'SingleCorrect' as QuizQuestionType,
    MultipleCorrect: 'MultipleCorrect' as QuizQuestionType,
    Any: 'Any' as QuizQuestionType
};