/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type WorkoutType = 'Unknown' | 'Gym' | 'Home' | 'Outdoor';

export const WorkoutType = {
    Unknown: 'Unknown' as WorkoutType,
    Gym: 'Gym' as WorkoutType,
    Home: 'Home' as WorkoutType,
    Outdoor: 'Outdoor' as WorkoutType
};