/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type UserWorkoutStatusType = 'Unknown' | 'Pending' | 'Missed' | 'Done';

export const UserWorkoutStatusType = {
    Unknown: 'Unknown' as UserWorkoutStatusType,
    Pending: 'Pending' as UserWorkoutStatusType,
    Missed: 'Missed' as UserWorkoutStatusType,
    Done: 'Done' as UserWorkoutStatusType
};