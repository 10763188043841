/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type MealsSortSpecification = 'NameASC' | 'NameDESC' | 'EnergyASC' | 'EnergyDESC' | 'CreatedByASC' | 'CreatedByDESC' | 'CreatedDateASC' | 'CreatedDateDESC' | 'IsUsedForLibraryASC' | 'IsUsedForLibraryDESC' | 'MealTypeASC' | 'MealTypeDESC';

export const MealsSortSpecification = {
    NameASC: 'NameASC' as MealsSortSpecification,
    NameDESC: 'NameDESC' as MealsSortSpecification,
    EnergyASC: 'EnergyASC' as MealsSortSpecification,
    EnergyDESC: 'EnergyDESC' as MealsSortSpecification,
    CreatedByASC: 'CreatedByASC' as MealsSortSpecification,
    CreatedByDESC: 'CreatedByDESC' as MealsSortSpecification,
    CreatedDateASC: 'CreatedDateASC' as MealsSortSpecification,
    CreatedDateDESC: 'CreatedDateDESC' as MealsSortSpecification,
    IsUsedForLibraryASC: 'IsUsedForLibraryASC' as MealsSortSpecification,
    IsUsedForLibraryDESC: 'IsUsedForLibraryDESC' as MealsSortSpecification,
    MealTypeASC: 'MealTypeASC' as MealsSortSpecification,
    MealTypeDESC: 'MealTypeDESC' as MealsSortSpecification
};