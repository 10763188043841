/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type EventStatusTypeModel = 'Unknown' | 'Draft' | 'Ready' | 'Running' | 'Finished' | 'Aborted';

export const EventStatusTypeModel = {
    Unknown: 'Unknown' as EventStatusTypeModel,
    Draft: 'Draft' as EventStatusTypeModel,
    Ready: 'Ready' as EventStatusTypeModel,
    Running: 'Running' as EventStatusTypeModel,
    Finished: 'Finished' as EventStatusTypeModel,
    Aborted: 'Aborted' as EventStatusTypeModel
};