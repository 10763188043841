/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type WorkoutVisibilityType = 'Unknown' | 'CreatedByCoach' | 'CreatedByClient' | 'CreatedByCoachForWorkoutLibrary';

export const WorkoutVisibilityType = {
    Unknown: 'Unknown' as WorkoutVisibilityType,
    CreatedByCoach: 'CreatedByCoach' as WorkoutVisibilityType,
    CreatedByClient: 'CreatedByClient' as WorkoutVisibilityType,
    CreatedByCoachForWorkoutLibrary: 'CreatedByCoachForWorkoutLibrary' as WorkoutVisibilityType
};