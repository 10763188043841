/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type GroupType = 'Unknown' | 'Coach' | 'Friends';

export const GroupType = {
    Unknown: 'Unknown' as GroupType,
    Coach: 'Coach' as GroupType,
    Friends: 'Friends' as GroupType
};