/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type IngredientsSortSpecification = 'NameASC' | 'NameDESC' | 'CreatedByASC' | 'CreatedByDESC' | 'CreatedDateASC' | 'CreatedDateDESC' | 'EnergyASC' | 'EnergyDESC' | 'ProteinASC' | 'ProteinDESC' | 'TotalCarbsASC' | 'TotalCarbsDESC' | 'SugarASC' | 'SugarDESC' | 'FatASC' | 'FatDESC';

export const IngredientsSortSpecification = {
    NameASC: 'NameASC' as IngredientsSortSpecification,
    NameDESC: 'NameDESC' as IngredientsSortSpecification,
    CreatedByASC: 'CreatedByASC' as IngredientsSortSpecification,
    CreatedByDESC: 'CreatedByDESC' as IngredientsSortSpecification,
    CreatedDateASC: 'CreatedDateASC' as IngredientsSortSpecification,
    CreatedDateDESC: 'CreatedDateDESC' as IngredientsSortSpecification,
    EnergyASC: 'EnergyASC' as IngredientsSortSpecification,
    EnergyDESC: 'EnergyDESC' as IngredientsSortSpecification,
    ProteinASC: 'ProteinASC' as IngredientsSortSpecification,
    ProteinDESC: 'ProteinDESC' as IngredientsSortSpecification,
    TotalCarbsASC: 'TotalCarbsASC' as IngredientsSortSpecification,
    TotalCarbsDESC: 'TotalCarbsDESC' as IngredientsSortSpecification,
    SugarASC: 'SugarASC' as IngredientsSortSpecification,
    SugarDESC: 'SugarDESC' as IngredientsSortSpecification,
    FatASC: 'FatASC' as IngredientsSortSpecification,
    FatDESC: 'FatDESC' as IngredientsSortSpecification
};