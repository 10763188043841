/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type ResourceScopeType = 'Unknown' | 'Global' | 'Organization' | 'Team' | 'Personal';

export const ResourceScopeType = {
    Unknown: 'Unknown' as ResourceScopeType,
    Global: 'Global' as ResourceScopeType,
    Organization: 'Organization' as ResourceScopeType,
    Team: 'Team' as ResourceScopeType,
    Personal: 'Personal' as ResourceScopeType
};